/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getLinkArticle } from '@/utils/utils';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import dynamic from 'next/dynamic';
import EncodeUrl from '@/utils/encode';

const OwlCarousel = dynamic(() => import('@/componentWebs/Global/OwlCarousel'), {
  ssr: false,
  loading: () => null,
});

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {
    dataIntroduce: [],
    isInternational: '1',
    key: Math.random(),
  };

  componentDidMount() {
    const { isInternational } = this.state;
    this.fetch(isInternational);
    window.addEventListener('resize', this.resize.bind(this), { passive: true });
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize() {
    this.setState({ hideNav: window.innerWidth < 1024 });
  }

  fetch = isInternational => {
    try {
      const { data, dispatch } = this.props;
      const { key } = this.state;
      dispatch({
        type: 'webs/fetchCatalogArticle',
        payload: {
          filter: {
            status: '1',
            productsCatalogCategoriesId: data.id,
            categoriesProductsCatalogStatus: '1',
            isInternational,
          },
          range: [0, 6],
          // attributes: `id,title,shortDescription,image,createDate,source,tag,author,urlSlugs,categoriesId`,
        },
        callback: res => {
          if (res && res.success) {
            const dataResults =
              res &&
              res.result &&
              res.result.list.length > 0 &&
              res.result.list.map(data => {
                return {
                  id: data.id,
                  title: data.title,
                  image: data.image,
                  createDate: data.createDate,
                  categories: data.categories,
                  urlSlugs: data.urlSlugs,
                };
              }) || [];
            this.setState({
              dataIntroduce: (dataResults.length > 0 && dataResults) || [],
              isInternational,
              key: key + 1,
            });
          } else {
            this.setState({
              dataIntroduce: [],
              key: key + 1,
            });
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleChangeTabs = International => {
    const { isInternational } = this.state;
    if (isInternational !== International) {
      this.fetch(International);
    }
  };

  render() {
    const { dataIntroduce, hideNav, isInternational, key } = this.state;
    // console.log(dataIntroduce);
    const { data } = this.props;
    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;
    // const {  }
    return (
      // Tin tức trang chủ
      <React.Fragment>
        {/* Tuyến bay yêu thích  */}
        <section className="favarioutPlane">
          <div className="container">
            <div className="favarioutPlane__wrapper">
              <div className="section-header-products height-auto-mobile">
                <h3 className="title">
                  <img
                    src="/static/vietnamTickets/images/subtraction_2.svg"
                    alt="Subtraction"
                    className="lazyload"
                    width="auto"
                    height="auto"
                  />
                  <span>{data.name}</span>
                </h3>
                <ul className="main-navigation">
                  <li className={`tab ${isInternational === '0' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('0')}>
                      <span>
                        <img
                          src="/static/vietnamTickets/images/NoPath - Copy (10).png"
                          alt="Nội địa"
                          className="lazyload"
                        />
                        Nội địa
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="202.068"
                        viewBox="0 0 202.068 51.844"
                      >
                        <path
                          id="Path_4163"
                          data-name="Path 4163"
                          d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                          transform="translate(-23289.355 14986.643)"
                          fill="#eb0281"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className={`tab ${isInternational === '1' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('1')}>
                      <span>
                        <img
                          src="/static/vietnamTickets/images/earth.svg"
                          alt="Nội địa"
                          className="lazyload"
                        />
                        Quốc tế
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="202.068"
                        viewBox="0 0 202.068 51.844"
                      >
                        <path
                          id="Path_4163"
                          data-name="Path 4163"
                          d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                          transform="translate(-23289.355 14986.643)"
                          fill="#eb0281"
                        />
                      </svg>
                    </a>
                  </li>
                  {/* etc. as many tabs as you'd like */}
                </ul>
              </div>
              <div className="bestHotel_tabs hidden-m-t">
                <ul className="bestHotel_tabs__list flex-a-c">
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/bestHotel.png"
                          alt="bestHotel"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Chất lượng tốt nhất</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/bestPrice.png"
                          alt="bestPrice"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Giá hấp dẫn nhất</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/UPdate.png"
                          alt="UPdate"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Cập nhật hàng tuần</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/special.png"
                          alt="special"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Dành riêng cho bạn</span>
                    </a>
                  </li>
                </ul>
              </div>
              {!hideNav ? (
                <div className="favarioutPlane-products">
                  <div className="row">
                    {dataIntroduce && dataIntroduce[0] && (
                      <div className="col-xl-6 col-lg-8">
                        <div className="favarioutPlane-products__box favarioutPlane-products__box-special">
                          <a
                            href={`/${EncodeUrl(dataIntroduce[0].categories.name)}/${dataIntroduce[0].urlSlugs
                              }/${dataIntroduce[0].id}`}
                            title={dataIntroduce[0].title}
                            className="favarioutPlane-products__box__img"
                          >
                            <img
                              src={getResponsiveImage(
                                dataIntroduce[0].image &&
                                dataIntroduce[0].image[0] &&
                                dataIntroduce[0].image[0].file,
                                imageResize
                              )}
                              alt={dataIntroduce[0].title}
                              title={dataIntroduce[0].title}
                              className="lazyload"
                            />
                          </a>
                          <div className="favarioutPlane-products__detail favarioutPlane-products__detail-special">
                            <div className="favarioutPlane-products__date favarioutPlane-products__date-main flex-a-c">
                              <img
                                src="/static/vietnamTickets/images/clock.svg"
                                alt="clock"
                                style={{ marginRight: '10px' }}
                                className="lazyload"
                              />
                              <span>
                                {moment(dataIntroduce[0].createDate).format('DD/MM/YYYY')}
                              </span>
                            </div>
                            <a
                              href={`/${EncodeUrl(dataIntroduce[0].categories.name)}/${dataIntroduce[0].urlSlugs
                                }/${dataIntroduce[0].id}`}
                              title={dataIntroduce[0].title}
                              className="favarioutPlane-products__title"
                            >
                              {dataIntroduce[0].title}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {dataIntroduce &&
                      dataIntroduce.length > 1 &&
                      dataIntroduce.slice(1).map(item => (
                        <div className="col-xl-3 col-lg-4" key={item.id}>
                          <div className="favarioutPlane-products__box">
                            <a
                              href={`/${EncodeUrl(item.categories.name)}/${item.urlSlugs}/${item.id
                                }`}
                              title={item.title}
                              className="box-product__img"
                            >
                              <img
                                src={getResponsiveImage(
                                  item.image && item.image[0] && item.image[0].file,
                                  imageResize
                                )}
                                alt={item.title}
                                title={item.title}
                                className="lazyload"
                              />
                            </a>
                            <div className="favarioutPlane-products__detail favarioutPlane-products__detail-small">
                              <div className="favarioutPlane-products__date flex-a-c">
                                <img
                                  src="/static/vietnamTickets/images/clock.svg"
                                  style={{ marginRight: '10px' }}
                                  alt="clock"
                                  className="lazyload"
                                />
                                <span>{moment(item.createDate).format('DD/MM/YYYY')}</span>
                              </div>
                              <a
                                href={`/${EncodeUrl(item.categories.name)}/${item.urlSlugs}/${item.id
                                  }`}
                                title={item.title}
                                className="favarioutPlane-products__title"
                              >
                                {item.title}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="wrapper-crosel-home">
                  {dataIntroduce && Array.isArray(dataIntroduce) && dataIntroduce.length > 0 && (
                    <OwlCarousel
                      key={`vemaybayHome_${key}`}
                      //   className="section-khachHang-owlCarousel"
                      responsive={{
                        0: {
                          items: 1,
                        },
                        425: {
                          items: 1,
                        },
                        768: {
                          items: 2,
                        },
                      }}
                      margin={30}
                      dots={false}
                      slideBy={1}
                    >
                      {dataIntroduce.map(item => (
                        <div className="favarioutPlane-products__box" key={item.id}>
                          <a
                            href={`/${EncodeUrl(item.categories.name)}/${item.urlSlugs}/${item.id}`}
                            title={item.title}
                            className="box-product__img"
                          >
                            <img
                              src={getResponsiveImage(
                                item.image && item.image[0] && item.image[0].file,
                                imageResize
                              )}
                              alt={item.title}
                              title={item.title}
                              className="lazyload"
                            />
                          </a>
                          <div className="favarioutPlane-products__detail favarioutPlane-products__detail-small">
                            <div className="favarioutPlane-products__date flex-a-c">
                              <img
                                src="/static/vietnamTickets/images/clock.svg"
                                style={{ marginRight: '10px' }}
                                alt="clock"
                                className="lazyload"
                              />
                              <span>{moment(item.createDate).format('DD/MM/YYYY')}</span>
                            </div>
                            <a
                              href={`/${EncodeUrl(item.categories.name)}/${item.urlSlugs}/${item.id
                                }`}
                              title={item.title}
                              className="favarioutPlane-products__title"
                            >
                              {item.title}
                            </a>
                          </div>
                        </div>
                      )) || null}
                    </OwlCarousel>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
